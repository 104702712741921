<template>
  <v-app>
    <v-app-bar app color="black" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-btn
        outlined
        class="headlink"
        to="/main"
        v-if="firebaseAnonymousUser"
        light
      >
        <h2><v-icon>mdi-home</v-icon> TokenArt.World</h2>
      </v-btn>
      <v-btn
        outlined
        class="headlink"
        to="/"
        v-if="!firebaseAnonymousUser"
        light
      >
        <!-- <h1>TokenArt.World</h1> -->
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn @click="userDrawer = !userDrawer" icon class="ma-1">
        <v-avatar>
          <v-img :src="user.profileImage"></v-img>
        </v-avatar>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app fixed temporary>
      <v-card>
        <v-card-text>
          <h2 class="my-2">Collection Methods:</h2>
          <ul class="ml-3">
            <li class="my-2">
              <h3>Visit the Event and locate the Art or Artist</h3>
            </li>
            <li class="my-2">
              <h3>
                Check the social media sites of the Artists or TokenArt.World
              </h3>
            </li>
            <li class="my-2 pink--text">
              <h3>Connect with another Collector</h3>
            </li>
            <li class="my-2">
              <h3>Find a TokenArt.World Real World Card</h3>
            </li>
          </ul>
        </v-card-text>
      </v-card>
      <v-spacer></v-spacer>

      <v-card>
        <v-card-actions v-if="currentUser.creator">
          <v-btn outlined to="/artwork-submission" block
            >Artwork Submission</v-btn
          >
        </v-card-actions>
        <v-card-actions>
          <v-btn color="orange" to="/scan-artwork" block dark
            ><v-icon class="mr-2">mdi-qrcode-scan</v-icon>Scan Artwork</v-btn
          >
        </v-card-actions>
        <v-card-actions>
          <v-btn color="blue" to="/user-collection" dark block v-if="!loggedIn">
            <span class="mr-2"
              ><v-icon class="mr-2">mdi-rhombus-split</v-icon>Discoveries</span
            >
          </v-btn>
          <v-btn
            color="blue"
            to="/pro-user-collection"
            dark
            block
            v-if="loggedIn"
          >
            <span class="mr-2"
              ><v-icon class="mr-2">mdi-rhombus-split</v-icon>Discoveries</span
            >
          </v-btn>
        </v-card-actions>
        <!-- <v-card-actions>
          <v-btn color="purple" to="/user" block dark
            ><v-icon class="mr-2">mdi-account</v-icon>User Profile</v-btn
          >
        </v-card-actions> -->
        <v-card-actions>
          <v-btn color="green" dark to="/artwork-locations" block
            ><v-icon class="mr-2">mdi-map</v-icon>Collections</v-btn
          >
        </v-card-actions>

        <v-card-actions>
          <v-btn outlined to="/privacy-policy" block>Privacy Policy</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="ma-1 mt-3 pa-1" light>
        <p class="text-center">An <strong>NFTW</strong> Project</p>
        <v-btn href="https://newfrontierworks.com" target="_blank" block dark
          >Visit Us</v-btn
        >
        <a href="https://newfrontierworks.com" target="_blank">
          <v-img
            class="mx-auto"
            src="https://cloudflare-ipfs.com/ipfs/Qmd2vqZgoP2h7J3EnxSa7oUVfDk9RfLd6TSBo1nhmV9yLQ"
            width="80%"
          ></v-img>
        </a>
      </v-card>
    </v-navigation-drawer>

    <!-- User Drawer  -->

    <v-navigation-drawer v-model="userDrawer" app right fixed temporary>
      <v-card class="nftw-box pa-2 text-center" rounded="0" aspect-ratio="1">
        <h2>Steward Info:</h2>
        <h3 v-if="user.name">{{ user.name }}</h3>
        <v-img
          alt="TokenArt.World Logo"
          class="ma-4"
          contain
          :placeholder="require('@/assets/TAW-Logo-white.svg')"
          :src="user.profileImage"
          transition="scale-transition"
        />
        <v-card-actions v-if="user.anonymous">
          <v-btn dark color="purple" block to="/login">
            <v-icon>mdi-account</v-icon> Login
          </v-btn>
        </v-card-actions>

        <v-card-actions v-if="user.anonymous">
          <v-spacer></v-spacer>
          <v-icon>mdi-google</v-icon>
          <v-spacer></v-spacer>
          <v-icon>mdi-apple</v-icon>
          <v-spacer></v-spacer>
          <v-icon>mdi-facebook</v-icon>
          <v-spacer></v-spacer>
          <v-icon>mdi-twitter</v-icon>
          <v-spacer></v-spacer>
          <v-icon>mdi-email</v-icon>
          <v-spacer></v-spacer>
          <v-icon>mdi-ethereum</v-icon>
          <v-spacer></v-spacer>
        </v-card-actions>
        <h4 v-if="user.email" class="text-center">
          {{ user.email }}
        </h4>
        <h5 v-if="web3balance">
          Account Balance: <br />{{ web3balance }} MATIC
        </h5>

        <!-- <v-card-actions v-if="!loggedIn">
          <torus-login></torus-login>
        </v-card-actions> -->
        <v-card-actions v-if="loggedIn">
          <v-btn to="/user" block color="purple" dark>Account Info</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            v-if="loggedIn"
            block
            color="green"
            dark
            :href="
              'https://juno.finance/partners/junopay?action=buy&currency=musdc&partnerKey=live_th2t6ysuok4xu11gykm2p65e&name=junopay&email=' +
              user.email +
              '&amount=30&walletAddress=' +
              user.address
            "
            target="_blank"
          >
            Buy Art Tokens
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <!-- Main  -->

    <v-main>
      <router-view />
    </v-main>

    <!-- Footer  -->

    <v-footer app v-if="firebaseAnonymousUser.uid != 'none'">
      <v-spacer class="d-none d-sm-flex"></v-spacer>

      <v-spacer></v-spacer>
      <v-btn
        class="mr-4 d-none d-sm-flex"
        color="orange"
        to="/scan-artwork"
        dark
      >
        <span class="mr-2">
          <v-icon class="mr-2">mdi-qrcode-scan</v-icon> Scanner
        </span>
      </v-btn>

      <v-spacer class="d-none d-sm-flex"></v-spacer>
      <v-btn color="purple" to="/user" dark v-if="!loggedIn">
        <span class="mr-2"><v-icon class="mr-2">mdi-account</v-icon>Login</span>
      </v-btn>
      <v-btn v-if="loggedIn" color="purple" to="/user" dark>
        <span class="mr-2"><v-icon class="mr-2">mdi-account</v-icon>User</span>
      </v-btn>
      <v-spacer v-if="!loggedIn"></v-spacer>
      <v-btn color="blue" to="/user-collection" dark v-if="!loggedIn">
        <span class="mr-2"
          ><v-icon class="mr-2">mdi-rhombus-split</v-icon>Collection</span
        >
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="blue" to="/pro-user-collection" dark v-if="loggedIn">
        <span class="mr-2"
          ><v-icon class="mr-2">mdi-rhombus-split</v-icon>Collection</span
        >
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-4"
        color="orange"
        to="/scan-artwork"
        fab
        top
        right
        absolute
        elevation="3"
        dark
      >
        <v-icon>mdi-qrcode-scan</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations, mapActions } from "vuex";
import firebase from "firebase/compat/app";
import RPC from "@/utils/web3rpc";
// import TorusLogin from "@/components/TorusWeb3AuthLogin.vue";

export default Vue.extend({
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "TokenArt.World",
    // all titles will be injected into this template
    titleTemplate: "%s | Art discovery, making anything real collectible",
  },
  // components: { TorusLogin },
  data: () => ({
    drawer: false,
    userDrawer: false,
    auth: {},
    web3: null,
    balance: null,
    provider: null,
    web3auth: null,
    clientIdTorus: process.env.VUE_APP_CLIENT_ID_TORUS_TESTNET,
    test: {},
    mainnet: false,
    appDrawer: false,
    accountAddress: null,
    //
  }),
  created() {
    this.loginFirebaseAnonymousAuthUser();
  },
  methods: {
    ...mapMutations([
      "setFirebaseAnonymousUser",
      "setIsNewUser",
      "setCurrentUser",
      "setCurrentProvider",
      "setCurrentWeb3Auth",
    ]),
    ...mapActions([
      "createAnonymousFirestoreUser",
      "logFirebaseAnonymousAuthUserReturn",
    ]),
    async getAddress() {
      const rpc = new RPC(this.provider);
      const address = await rpc.getAccounts();
      console.log("address", address);
      this.setWeb3Account(address);
      this.accountAddress = address;
    },
    // Get user's balance in ether
    async getBalance() {
      const rpc = new RPC(this.provider);
      this.balance = await rpc.getBalance();
      this.setWeb3Balance(this.balance);

      console.log(this.balance);
    },
    async loginFirebaseAnonymousAuthUser() {
      firebase
        .auth()
        .signInAnonymously()
        .then((userCredential) => {
          // Set if user is new or returning
          this.setIsNewUser(userCredential.additionalUserInfo.isNewUser);
          this.setFirebaseAnonymousUser(userCredential.user);
          // console.log(userCredential.user);

          if (userCredential.additionalUserInfo.isNewUser) {
            this.createAnonymousFirestoreUser(userCredential.user);
            this.logFirebaseAnonymousAuthUserReturn(userCredential.user);
            console.log("newbie");
          } else {
            // this.logFirebaseAnonymousAuthUserReturn(userCredential.user)
            this.logFirebaseAnonymousAuthUserReturn(userCredential.user);
            console.log("return");
          }

          // Get User Credential from Google
          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          // ...
        });
    },
  },
  computed: mapState([
    "user",
    "web3account",
    "web3balance",
    "proUserConnections",
    "currentUser",
    "isNewUser",
    "loggedIn",
    "firebaseAnonymousUser",
    "scanResultObject",
    "scanResultCID",
    "sharedUserID",
    "sharedUserInfo",
    "currentProvider",
  ]),
});
</script>

<style>
main {
  background-repeat: repeat;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='1000' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23FAFAFA' stroke-width='1.8'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23F6F6F6'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}
.headlink {
  text-decoration: none !important;
  color: white !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Righteous, Haettenschweiler, Franklin Gothic Bold, Charcoal,
    Helvetica Inserat, Bitstream Vera Sans Bold, Arial Black, sans serif;
  text-transform: none;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}

.nftw-box {
  border-style: solid;
  border-color: black;
  border-width: 5px;
}
</style>