import Vue from "vue";
import Vuex from "vuex";
import localStoragePlugin from "@/plugins/localStorage";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import firebase from "firebase/compat/app";
import { db } from "@/firebase/db";

Vue.use(Vuex);

const incrementOne = firebase.firestore.FieldValue.increment(1);

export default new Vuex.Store({
  plugins: [localStoragePlugin],

  state: {
    // user related
    firebaseAnonymousUser: { uid: "none" },
    user: {
      profileImage: require("@/assets/TAW-Logo-white.svg"),
      anonymous: true,
    },
    currentUser: {
      profile: {
        greenPlayer: true,
      },
      profileCID: "bafyreihdscmxuipqavemwka2aaitasv22ep2jkzssnet5meqmemtmxjthy",
      user: {
        address: "",
        chainBase: "",
      },
    },
    userProfile: null,
    userElections: {},
    isNewUser: true,
    loggedIn: false,

    // Shared User

    stewardUserProfile: null,
    error: null,
    firebaseArtworks: [],
    currentArtworkCollection: [{ tokenDiscovery: 0 }],
    featuredArtworkCollection: [
      {
        artworkObject: {
          image: "ipfs://QmcHcHzcyFnPQEinmDAExUrJdNYkWgwuWs8PUV4vFyZWEF",
        },
      },
    ],
    // web3
    currentWeb3Auth: null,
    currentProvider: null,
    currentChain: "Polygon Mainnet",
    web3account: null,
    web3balance: null,
    provider: null,

    coloradoColorConSpaces: null,

    // scan result
    scanResultCID: "",
    scanResultObject: {},
    // artwork submission
    artworkSubmissionCID: "",
    artworkSubmissionURL: "",
    artworkSubmissionObject: {},
    // current artwork
    currentArtworkCID: "No Scan",
    currentArtworkObject: {
      artworkCID: "No Scan",
      artworkObject: {
        name: "NFTW",
        image:
          "https://cloudflare-ipfs.com/ipfs/QmcHcHzcyFnPQEinmDAExUrJdNYkWgwuWs8PUV4vFyZWEF",
      },
    },
    // collections
    anonymousArtworkCollection: [],
    proArtworkCollection: [{ properties: { organization: "NFTW" } }],
    preMintCollection: [],
    proUserConnections: [],
    nftwArtworkCollection: [],
    proArtworkOrders: [{ orderValue: 0 }],
  },
  getters: {
    currentArtworkObject(state) {
      return state.currentArtworkObject;
    },
    currentAnonymousArtworkCollection(state) {
      return state.anonymousArtworkCollection;
    },
    totalOrderValue: (state) => {
      let sum = 0;
      state.proArtworkOrders.forEach((order) => (sum += order.orderValue));
      return sum;
    },
    totalDiscoveries: (state) => {
      let sum = 0;
      state.currentArtworkCollection.forEach(
        (artwork) => (sum += artwork.tokenDiscovery)
      );
      return sum;
    },
  },
  mutations: {
    ...vuexfireMutations,
    initializeStore() {
      const data = localStorage.getItem("tawState-001");

      if (data) {
        this.replaceState(Object.assign(this.state, JSON.parse(data)));
      }
    },
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    setCurrentUser(state, user) {
      state.user = user;
    },
    setCurrentWeb3Auth(state, web3auth) {
      state.currentWeb3Auth = web3auth;
    },
    setCurrentProvider(state, provider) {
      state.currentProvider = provider;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setFirebaseAnonymousUser(state, payload) {
      state.firebaseAnonymousUser = payload;
    },
    setIsNewUser(state, payload) {
      state.isNewUser = payload;
    },
    // Scan Result
    updateScanResultCID(state, payload) {
      state.scanResultCID = payload;
    },
    updateScanResultObject(state, payload) {
      state.scanResultObject = payload;
    },
    // Artwork Submission
    updateArtworkSubmissionResultCID(state, payload) {
      state.artworkSubmissionCID = payload;
    },
    updateArtworkSubmissionResultURL(state, payload) {
      state.artworkSubmissionURL = payload;
    },
    updateArtworkSubmissionObject(state, payload) {
      state.artworkSubmissionObject = payload;
    },
    // Current Artwork Object
    updateCurrentArtworkObject(state, payload) {
      state.currentArtworkObject = payload;
    },
    updateCurrentItemCID(state, payload) {
      state.currentArtworkCID = payload;
    },
    // Set Web 3
    setWeb3Account(state, account) {
      state.web3account = account;
    },
    setWeb3Balance(state, balance) {
      state.web3balance = balance;
    },
    setUserProfile(state, payload) {
      state.userProfile = payload;
    },

    logoutTorus(state) {
      state.user = {
        profileImage: require("@/assets/TAW-Logo-white.svg"),
        anonymous: true,
      };
      state.loggedIn = false;

      state.web3account = null;
      state.web3balance = null;
    },
  },
  actions: {
    // Anonymous User Activity
    createAnonymousFirestoreUser: firestoreAction((context, anonymousUser) => {
      // return the promise so we can await the write
      return db.collection("anonymous-users").doc(anonymousUser.uid).set({
        uid: anonymousUser.uid,
        from: "TokenArt.World-Anonymous",
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }),
    logFirebaseAnonymousAuthUserReturn: firestoreAction(
      (context, anonymousUser) => {
        // return the promise so we can await the write
        return db
          .collection("anonymous-users")
          .doc(anonymousUser.uid)
          .collection("logins")
          .add({
            from: "TokenArt.World-Anonymous",
            dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
          });
      }
    ),
    // Public User Activity
    createPublicFirestoreUser: firestoreAction((context, publicUser) => {
      // return the promise so we can await the write
      return db.collection("public-users").doc(publicUser.address).set(
        {
          publicUser,
          originUID: publicUser.uid,
          chainBase: publicUser.chainBase,
          from: "TokenArt.World-Public",
          lastVisit: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
    }),
    logFirebasePublicAuthUserReturn: firestoreAction((context, publicUser) => {
      // return the promise so we can await the write
      return db
        .collection("public-users")
        .doc(publicUser.address)
        .collection("logins")
        .add({
          originUID: publicUser.uid,
          from: "TokenArt.World-Public",
          dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }),

    // User Elections

    updatePublicUserElections: firestoreAction((context, payload) => {
      return db
        .collection("public-users")
        .doc(payload.currentUserAddress)
        .update({
          elections: payload.elections,
          updateDateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }),
    // User Profile

    updatePublicUserProfile: firestoreAction((context, payload) => {
      return db
        .collection("public-users")
        .doc(payload.currentUserAddress)
        .update({
          profileCID: payload.profileCID,
          profile: { greenPlayer: false },
          updateDateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }),

    // Add Artwork to Collection

    addArtworkToFirebaseCollection: firestoreAction((context, payload) => {
      // return the promise so we can await the write
      return db.collection("valid-artwork").doc(payload.artworkCID).set({
        submittedBy: payload.submittedBy,
        artworkCID: payload.artworkCID,
        organization: payload.organization,
        artworkObject: payload.artworkObject,
        tokenDiscovery: 0,
        tokenOrder: 0,
        tokenValue: 0,
        dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }),

    // Update Token Discovery Stats

    updateTokenStatsDiscoveryFirebaseCollection: firestoreAction(
      (context, payload) => {
        // return the promise so we can await the write
        return db.collection("valid-artwork").doc(payload.artworkCID).update({
          tokenDiscovery: incrementOne,
        });
      }
    ),
    updateTokenStatsDiscoveryAnonymousUserCollection: firestoreAction(
      (context, anonymousUser) => {
        // return the promise so we can await the write
        return db
          .collection("anonymous-users")
          .doc(anonymousUser.uid)
          .update({
            tokenStats: {
              tokenDiscovery: incrementOne,
            },
          });
      }
    ),
    updateTokenStatsDiscoveryPublicUserCollection: firestoreAction(
      (context, publicUser) => {
        // return the promise so we can await the write
        return db
          .collection("public-users")
          .doc(publicUser.address)
          .update({
            tokenStats: {
              tokenDiscovery: incrementOne,
            },
          });
      }
    ),

    // Update Token Order Stats

    updateTokenStatsOrderFirebaseCollection: firestoreAction(
      (context, payload) => {
        // return the promise so we can await the write
        // return db.collection('valid-artwork').doc(payload.artworkCID).set({
        //   tokenOrder: increment
        // })
        return db
          .collection("valid-artwork")
          .doc(payload.artworkCID)
          .update({
            tokenOrder: incrementOne,
            tokenValue: firebase.firestore.FieldValue.increment(
              payload.orderValue
            ),
          });
      }
    ),

    // Discovery Section

    addArtworkDiscoveryToFirebaseCollection: firestoreAction(
      (context, payload) => {
        return db
          .collection("valid-artwork")
          .doc(payload.artworkCID)
          .collection("favorites")
          .add({
            discoveredByAnonymous: payload.discoveredByAnonymous,
            discoveredByPublic: payload.discoveredByPublic,
            artworkCID: payload.artworkCID,
            dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
          });
      }
    ),

    addArtworkDiscoveryToAnonymousUser: firestoreAction((context, payload) => {
      return db
        .collection("anonymous-users")
        .doc(payload.discoveredByAnonymous)
        .collection("favorites")
        .doc(payload.artworkCID)
        .set({
          artworkCID: payload.artworkCID,
          artworkObject: payload.artworkObject,
          discoveryLocation: "TokenArt.World",
          dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }),

    addArtworkDiscoveryToPublicUser: firestoreAction((context, payload) => {
      return db
        .collection("public-users")
        .doc(payload.discoveredByPublic.address)
        .collection("favorites")
        .doc(payload.artworkCID)
        .set({
          artworkCID: payload.artworkCID,
          artworkObject: payload.artworkObject,
          discoveryLocation: "TokenArt.World",
          dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }),

    // User Connection

    addUserConnectionToAnonymousUser: firestoreAction((context, payload) => {
      return db
        .collection("anonymous-users")
        .doc(payload.discoveredByAnonymous)
        .collection("connections")
        .doc(payload.artworkCID)
        .set({
          sharedBy: payload.sharedBy,
          artworkCID: payload.artworkCID,
          discoveryLocation: "TokenArt.World",
          dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }),

    addUserConnectionToPublicUser: firestoreAction((context, payload) => {
      return db
        .collection("public-users")
        .doc(payload.discoveredByPublic.user.address)
        .collection("connections")
        .doc(payload.sharedBy)
        .set({
          sharedBy: payload.sharedBy,
          artworkCID: payload.artworkCID,
          discoveryLocation: "TokenArt.World",
          dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }),

    claimArtworkDiscoveryToPublicUser: firestoreAction((context, payload) => {
      return db
        .collection("public-users")
        .doc(payload.user.address)
        .collection("discoveries")
        .doc(payload.artwork.artworkCID)
        .set({
          artworkCID: payload.artwork.artworkCID,
          artworkObject: payload.artwork.artworkObject,
          discoveryLocation: "TokenArt.World",
          dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }),

    //Order Section

    addArtworkOrderToFirebaseCollection: firestoreAction((context, payload) => {
      // return the promise so we can await the write
      return db
        .collection("valid-artwork")
        .doc(payload.artworkCID)
        .collection("orders")
        .add({
          orderedByAnonymous: payload.anonymousUser.uid,
          orderedByPublicAddress: payload.publicUser.address,
          artworkCID: payload.artworkCID,
          orderValue: payload.orderValue,
          dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }),
    addArtworkOrderToPublicUserOrders: firestoreAction((context, payload) => {
      // return the promise so we can await the write
      return db
        .collection("public-users")
        .doc(payload.publicUser.address)
        .collection("orders")
        .add({
          orderedByAnonymous: payload.anonymousUser.uid,
          orderedByPublicAddress: payload.publicUser.address,
          artworkCID: payload.artworkCID,
          artworkObject: payload.artworkObject,
          orderValue: payload.orderValue,
          dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }),

    addMintReservationToPublicUser: firestoreAction((context, payload) => {
      return db
        .collection("public-users")
        .doc(payload.user.address)
        .collection("mint-orders")
        .doc(payload.spaceRef.spaceID)
        .set({
          orderedByPublicAddress: payload.user.address,
          spaceRef: payload.spaceRef,
          mintRef: payload.mintRef,
          dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }),

    // Bind User Info

    bindPublicUser: firestoreAction(({ bindFirestoreRef }, publicUser) => {
      return bindFirestoreRef(
        "currentUser",
        db.collection("public-users").doc(publicUser.address)
      );
    }),
    unbindPublicUser: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("currentUser");
    }),
    bindSharedUser: firestoreAction(({ bindFirestoreRef }, sharedUser) => {
      return bindFirestoreRef(
        "sharedUserInfo",
        db.collection("public-users").doc(sharedUser)
      );
    }),
    unbindSharedUser: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("sharedUserInfo");
    }),

    // Bind or Unbind to Collections

    bindCollections: firestoreAction(({ bindFirestoreRef }, collectionID) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        "currentArtworkCollection",
        db.collection("valid-artwork").where("organization", "==", collectionID)
      );
    }),
    unbindCollections: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("currentArtworkCollection");
    }),
    bindFeaturedCollection: firestoreAction(
      ({ bindFirestoreRef }, collectionID) => {
        // return the promise returned by `bindFirestoreRef`
        return bindFirestoreRef(
          "featuredArtworkCollection",
          db
            .collection("valid-artwork")
            .where("organization", "==", collectionID)
        );
      }
    ),
    unbindFeaturedCollection: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("featuredArtworkCollection");
    }),
    bindNFTWCollection: firestoreAction(
      ({ bindFirestoreRef }, collectionID) => {
        // return the promise returned by `bindFirestoreRef`
        return bindFirestoreRef(
          "nftwArtworkCollection",
          db.collection("valid-artwork").where("organization", "==", "NFTW")
        );
      }
    ),
    unbindNFTWCollection: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("nftwArtworkCollection");
    }),

    bindColoradoConSpaces: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        "coloradoColorConSpaces",
        db
          .collection("event-spaces")
          .where("event", "==", "Colorado COLORCON 2022")
      );
    }),
    unbindColoradoConSpaces: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("coloradoColorConSpaces");
    }),

    bindArtworks: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        "firebaseArtworks",
        db.collection("valid-artwork")
      );
    }),
    unbindArtworks: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("firebaseArtworks");
    }),
    bindAnonymousUserCollection: firestoreAction(
      ({ bindFirestoreRef }, anonymousUser) => {
        // return the promise returned by `bindFirestoreRef`
        return bindFirestoreRef(
          "anonymousArtworkCollection",
          db
            .collection("anonymous-users")
            .doc(anonymousUser.uid)
            .collection("favorites")
        );
      }
    ),
    unbindAnonymousUserCollection: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("anonymousArtworkCollection");
    }),
    bindPublicUserCollection: firestoreAction(
      ({ bindFirestoreRef }, publicUser) => {
        // return the promise returned by `bindFirestoreRef`
        return bindFirestoreRef(
          "proArtworkCollection",
          db
            .collection("public-users")
            .doc(publicUser.address)
            .collection("favorites")
        );
      }
    ),
    unbindPublicUserCollection: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("proArtworkCollection");
    }),
    bindPublicUserTokenPreMints: firestoreAction(
      ({ bindFirestoreRef }, publicUser) => {
        // return the promise returned by `bindFirestoreRef`
        return bindFirestoreRef(
          "preMintCollection",
          db
            .collection("public-users")
            .doc(publicUser.address)
            .collection("mint-orders")
        );
      }
    ),
    unbindPublicUserTokenPreMints: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("preMintCollection");
    }),
    bindPublicUserConnections: firestoreAction(
      ({ bindFirestoreRef }, publicUser) => {
        // return the promise returned by `bindFirestoreRef`
        return bindFirestoreRef(
          "proUserConnections",
          db
            .collection("public-users")
            .doc(publicUser.address)
            .collection("connections")
        );
      }
    ),
    unbindPublicUserConnections: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("proUserConnections");
    }),
    bindPublicUserOrders: firestoreAction(
      ({ bindFirestoreRef }, publicUser) => {
        // return the promise returned by `bindFirestoreRef`
        return bindFirestoreRef(
          "proArtworkOrders",
          db
            .collection("public-users")
            .doc(publicUser.address)
            .collection("orders")
        );
      }
    ),
    unbindPublicUserOrder: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("proArtworkOrders");
    }),
  },
  modules: {},
});
