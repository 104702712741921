<template>
  <v-container class="fill-height" dark>
    <v-row align="center" justify="center" class="fill-height">
      <div class="container">
        <!-- <h3 v-if="$route.params.uid">Shared</h3> -->
        <v-img
          v-if="$route.params.cid && scanResultObject.image"
          id="FoundImage"
          :placeholder="require('../assets/TAW-Logo.svg')"
          :src="
            'https://cloudflare-ipfs.com/ipfs/' +
            this.scanResultObject.image.split('ipfs://')[1]
          "
          max-height="420"
        ></v-img>

        <v-overlay opacity="0.3">
          <v-img
            id="LogoOverlay"
            :src="require('../assets/TAW-Logo.svg')"
            alt="NFTW Logo"
            class="my-1"
            contain
            max-height="400px"
            max-width="400px"
          />
          <v-btn
            class="btn pa-3"
            variant="outlined"
            icon
            size="x-large"
            @click="openDoor"
            >Enter
          </v-btn>
        </v-overlay>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  name: "TheDoor",
  created() {
    if (this.$route.params.cid) {
      this.updateScanResultCID(this.$route.params.cid);
      axios
        .get(
          "https://cloudflare-ipfs.com/ipfs/" +
            this.$route.params.cid +
            "/metadata.json"
        )
        .then((response) => this.updateScanResultObject(response.data));
      // console.log(this.scanResultObject)
      this.backgroundImage = this.scanResultObject.image;
    } else {
      // console.log("no scan");
    }

    // If Shared User

    if (this.$route.params.uid) {
      this.updatedSharedUserID(this.$route.params.uid);
    }
  },
  methods: {
    ...mapMutations([
      "updateScanResultCID",
      "updateScanResultObject",
      "updatedSharedUserID",
    ]),
    openDoor() {
      this.$router.push("/main");
    },
  },
  computed: mapState(["scanResultObject", "scanResultCID"]),
};
</script>

<style scoped>
.container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.container img {
  width: 100%;
  height: auto;
}
/* #FoundImage {
  z-index: 20;
}

#LogoOverlay {
  z-index: 100;
} */

.container .v-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* z-index: 200; */
}
</style>
