import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
// import {firestorePlugin} from 'vuefire';
import "@/plugins/firebase"


// Vue.use(firestorePlugin);

Vue.prototype.$axios = axios;

Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  created() {
    store.commit('initializeStore');
  },
  render: h => h(App)
}).$mount('#app')
