import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"dark":""}},[_c(VRow,{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"container"},[(_vm.$route.params.cid && _vm.scanResultObject.image)?_c(VImg,{attrs:{"id":"FoundImage","placeholder":require('../assets/TAW-Logo.svg'),"src":'https://cloudflare-ipfs.com/ipfs/' +
          this.scanResultObject.image.split('ipfs://')[1],"max-height":"420"}}):_vm._e(),_c(VOverlay,{attrs:{"opacity":"0.3"}},[_c(VImg,{staticClass:"my-1",attrs:{"id":"LogoOverlay","src":require('../assets/TAW-Logo.svg'),"alt":"NFTW Logo","contain":"","max-height":"400px","max-width":"400px"}}),_c(VBtn,{staticClass:"btn pa-3",attrs:{"variant":"outlined","icon":"","size":"x-large"},on:{"click":_vm.openDoor}},[_vm._v("Enter ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }