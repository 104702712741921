import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import "firebase/compat/analytics";

// Get a Firestore instance
export const db = firebase
  .initializeApp({ apiKey: "AIzaSyDhjta-2FU63mDy7ANR_Tp5i2HptarPDJ8",
  authDomain: "nftw-art-world.firebaseapp.com",
  projectId: "nftw-art-world",
  storageBucket: "nftw-art-world.appspot.com",
  messagingSenderId: "964963803522",
  appId: "1:964963803522:web:f60158a1c503a3dbfbaf9b",
  measurementId: "G-FZ6NFDXZ70" })
  .firestore()

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { TimeStamp, GeoPoint } = firebase.firestore
export { TimeStamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
db.settings({ timestampsInSnapshots: true })

export const analytics = firebase.analytics();