import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'


const firebaseConfig = {
    apiKey: "AIzaSyDhjta-2FU63mDy7ANR_Tp5i2HptarPDJ8",
  authDomain: "nftw-art-world.firebaseapp.com",
  projectId: "nftw-art-world",
  storageBucket: "nftw-art-world.appspot.com",
  messagingSenderId: "964963803522",
  appId: "1:964963803522:web:f60158a1c503a3dbfbaf9b",
  measurementId: "G-FZ6NFDXZ70"
};

firebase.initializeApp(firebaseConfig);
