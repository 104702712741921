import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Meta from 'vue-meta'

Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cid/:cid',
    name: 'foundArtworkItemRouted',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: HomeView
  },
  {
    path: '/cid/:cid/uid/:uid',
    name: 'foundArtworkItemRoutedUser',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: HomeView
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/nft',
    name: 'nftInfoPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "nftInfoPage" */ '../views/nftInfoPage.vue')
  },
  {
    path: '/login',
    name: 'loginView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "loginView" */ '../views/LoginView.vue')
  },
  {
    path: '/user',
    name: 'activeUserView',
    // meta: {requiresAuth: true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "activeUserView" */ '../views/ActiveUserView.vue')
  },
  {
    path: '/account-management',
    name: 'accountManagement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "accountManagement" */ '../views/AccountManagement.vue')
  },
  {
    path: '/entry-way',
    name: 'entryWay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "entryWay" */ '../views/EntryWay.vue')
  },
  {
    path: '/main',
    name: 'mainMenu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mainMenu" */ '../views/MainMenu.vue')
  },
  {
    path: '/found-item',
    name: 'foundArtworkItem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "foundArtworkItem" */ '../views/FoundArtworkDisplay.vue')
  },
  {
    path: '/artwork-item',
    name: 'artworkItem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "artworkItem" */ '../views/ArtworkItemDisplay.vue')
  },
  {
    path: '/art/:cid',
    name: 'artworkItemByCID',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "artworkItemByCID" */ '../views/ArtworkItemDisplay.vue')
  },
  {
    path: '/user-collection',
    name: 'anonymousUserCollectionGrid',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "anonymousUserCollectionGrid" */ '../views/AnonymousUserCollectionGrid.vue')
  },
  {
    path: '/pro-user-collection',
    name: 'publicUserCollectionGrid',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "publicUserCollectionGrid" */ '../views/PublicUserCollectionGrid.vue')
  },
  {
    path: '/pro-user-connections',
    name: 'publicUserConnectionGrid',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "publicUserConnectionGrid" */ '../views/PublicUserConnectionGrid.vue')
  },
  {
    path: '/total-collection',
    name: 'allArtworksCollectionGrid',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "allArtworksCollectionGrid" */ '../views/AllArtworksCollectionGrid.vue')
  },
  {
    path: '/collection/:collectionID',
    name: 'CollectionGridByID',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CollectionGridByID" */ '../views/CollectionGridByID.vue')
  },
  {
    path: '/scan-artwork',
    name: 'scanArtwork',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "scanArtwork" */ '../views/ScanArtwork.vue')
  },
  {
    path: '/artwork-locations',
    name: 'artworkLocations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "artworkLocations" */ '../views/ArtLocations.vue')
  },
  {
    path: '/whitelist-artworks',
    name: 'whitelistArtworks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "whitelistArtworks" */ '../views/WhiteListArtwork.vue')
  },
  {
    path: '/new-user-submission',
    name: 'newUserSubmission',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "newUserSubmission" */ '../views/NewUserSubmission.vue')
  },
  {
    path: '/artwork-submission',
    name: 'artworkSubmission',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "artworkSubmission" */ '../views/ArtworkSubmission.vue')
  },
  {
    path: '/preview-artwork-submission',
    name: 'previeArtworkSubmission',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "previeArtworkSubmission" */ '../views/PreviewArtworkSubmission.vue')
  },
  {
    path: '/event/colorado-color-con',
    name: 'coloradoColorCon',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coloradoColorCon" */ '../views/event/ColoradoColorCon.vue')
  },
  {
    path: '/event/colorado-state-capitol',
    name: 'coloradoStateCapitol',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coloradoStateCapitol" */ '../views/event/ColoradoStateCapitol.vue')
  },
  {
    path: '/event/colorado-state-capitol/:cid',
    name: 'coloradoStateCapitol',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coloradoStateCapitol" */ '../views/event/ColoradoStateCapitol.vue')
  },
  {
    path: '/event/colorado-color-con/:spaceID',
    name: 'coloradoColorConSpacePage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coloradoColorConSpacePage" */ '../views/event/ColoradoColorConSpacePage.vue')
  },
  {
    path: '/event/colorado-color-con/:spaceID/:mintID',
    name: 'coloradoColorConMintPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coloradoColorConMintPage" */ '../views/event/ColoradoColorConMintPage.vue')
  },
  {
    path: '/event/colorado-colorcon',
    name: 'coloradoColorCon',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coloradoColorCon" */ '../views/event/ColoradoColorCon.vue')
  },
  {
    path: '/event/colorado-colorcon/:spaceID',
    name: 'coloradoColorConSpacePage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coloradoColorConSpacePage" */ '../views/event/ColoradoColorConSpacePage.vue')
  },
  {
    path: '/event/colorado-colorcon/:spaceID/:mintID',
    name: 'coloradoColorConMintPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coloradoColorConMintPage" */ '../views/event/ColoradoColorConMintPage.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'home'  && !store.state.firebaseAnonymousUser) next({ name: 'home' })
//   else next()
// })

export default router
